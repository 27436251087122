@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Good Timing';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/good-timing-bd.ttf') format('truetype');
}
